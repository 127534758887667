// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-abrazo-js": () => import("./../../../src/pages/abrazo.js" /* webpackChunkName: "component---src-pages-abrazo-js" */),
  "component---src-pages-el-barrio-js": () => import("./../../../src/pages/el-barrio.js" /* webpackChunkName: "component---src-pages-el-barrio-js" */),
  "component---src-pages-el-salon-js": () => import("./../../../src/pages/el-salon.js" /* webpackChunkName: "component---src-pages-el-salon-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-recepcion-js": () => import("./../../../src/pages/la-recepcion.js" /* webpackChunkName: "component---src-pages-la-recepcion-js" */),
  "component---src-pages-muchas-js": () => import("./../../../src/pages/muchas.js" /* webpackChunkName: "component---src-pages-muchas-js" */),
  "component---src-pages-qr-landing-js": () => import("./../../../src/pages/qr-landing.js" /* webpackChunkName: "component---src-pages-qr-landing-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */)
}

